import React, {useContext} from "react";
import "./Painting.scss";
import {painting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import ImageGallery from 'react-image-gallery';

export default function Painting() {
  const {isDark} = useContext(StyleContext);

  const google_url = 'https://drive.google.com/uc?id='
  const images = [
    {
      original: `${google_url}1vnXPKaUEk6XuPUdwyITMqs-sOqdnEumc`,
      thumbnail: `${google_url}1vnXPKaUEk6XuPUdwyITMqs-sOqdnEumc`,
    },
    {
      original: `${google_url}1793OtufNLtaLjN2oL4lPpuPVzOG79SmH`,
      thumbnail: `${google_url}1793OtufNLtaLjN2oL4lPpuPVzOG79SmH`,
    },
    {
      original: `${google_url}1qja9yBXqmADYdFYtAVgqwHUztX_vtWzI`,
      thumbnail: `${google_url}1qja9yBXqmADYdFYtAVgqwHUztX_vtWzI`,
    },
    {
      original: `${google_url}1BukJGcU51wcC76ZP4Y5E6uBhlOGRGI-V`,
      thumbnail: `${google_url}1BukJGcU51wcC76ZP4Y5E6uBhlOGRGI-V`,
    },
    {
      original: `${google_url}1HsgEcdDCt4yQV_yqZuMpZ7vK8nOkeNIW`,
      thumbnail: `${google_url}1HsgEcdDCt4yQV_yqZuMpZ7vK8nOkeNIW`,
    },
    {
      original: `${google_url}1kFCGchKjXBWeMdyyOrFeMUyDdiY75NyN`,
      thumbnail: `${google_url}1kFCGchKjXBWeMdyyOrFeMUyDdiY75NyN`,
    },
    {
      original: `${google_url}19IyTiwoA_ieRPvB0rYATNN8ISRZrZUyg`,
      thumbnail: `${google_url}19IyTiwoA_ieRPvB0rYATNN8ISRZrZUyg`,
    },
    {
      original: `${google_url}1WRAn5AXrAdy5z5HD8O5kuFHxItwzTR34`,
      thumbnail: `${google_url}1WRAn5AXrAdy5z5HD8O5kuFHxItwzTR34`,
    },
    {
      original: `${google_url}1p_wcxiR6gs1x-i0TPFYjptPy0oxQcEI3`,
      thumbnail: `${google_url}1p_wcxiR6gs1x-i0TPFYjptPy0oxQcEI3`,
    },
    {
      original: `${google_url}1hbhvvz4sMbz_FP-VgNuk2eIoOoxaMGvA`,
      thumbnail: `${google_url}1hbhvvz4sMbz_FP-VgNuk2eIoOoxaMGvA`,
    },
    {
      original: `${google_url}147vif57Wx1CMe5E8AKjnnZC7VIonZ8Y6`,
      thumbnail: `${google_url}147vif57Wx1CMe5E8AKjnnZC7VIonZ8Y6`,
    },
    {
      original: `${google_url}1-V90O3Tcnx0cdHKuk006DhPuOJoPkbZ_`,
      thumbnail: `${google_url}1-V90O3Tcnx0cdHKuk006DhPuOJoPkbZ_`,
    },
    {
      original: `${google_url}19pZuRROtbmP6r8S5w5_kX6YNbi0ChxTq`,
      thumbnail: `${google_url}19pZuRROtbmP6r8S5w5_kX6YNbi0ChxTq`,
    },
    {
      original: `${google_url}1yVPvjHkfF42j3hXoY5bD6B2IblQBr2ee`,
      thumbnail: `${google_url}1yVPvjHkfF42j3hXoY5bD6B2IblQBr2ee`,
    },
    {
      original: `${google_url}1T_Xsjw-m3LYss6cziXCslr2oTU0vX3zR`,
      thumbnail: `${google_url}1T_Xsjw-m3LYss6cziXCslr2oTU0vX3zR`,
    },
    {
      original: `${google_url}1T_Xsjw-m3LYss6cziXCslr2oTU0vX3zR`,
      thumbnail: `${google_url}1T_Xsjw-m3LYss6cziXCslr2oTU0vX3zR`,
    },
    {
      original: `${google_url}1yiWPqUYm32R7MB3DWlX9ElYX9-dE7-pd`,
      thumbnail: `${google_url}1yiWPqUYm32R7MB3DWlX9ElYX9-dE7-pd`,
    },
  ];

  if(!painting.display){
    return null;
  }

  return (
    <div className="main">
      <div id="painting">
        <h1 className="painting-heading">Painting</h1>
        <p> You might be blocked by Google auto-query detection</p> 
        <ImageGallery items={images} />
      </div>
    </div>
  );
}
