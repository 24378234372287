import React, {useContext} from "react";
import "./Photography.scss";
import {photography} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import ImageGallery from 'react-image-gallery';

export default function Photography() {
  const {isDark} = useContext(StyleContext);

  const google_url = 'https://drive.google.com/uc?id='
  const images = [
    {
      original: `${google_url}1l59QIqmGcBzvjSk8afBw3geuZeStrq3p`,
      thumbnail: `${google_url}1l59QIqmGcBzvjSk8afBw3geuZeStrq3p`,
    },
    {
      original: `${google_url}1_EoDpHnR-dSI09GLxcuYrMXDub39obAt`,
      thumbnail: `${google_url}1_EoDpHnR-dSI09GLxcuYrMXDub39obAt`,
    },
    {
      original: `${google_url}13zo6SgOhhKE995fnJnCWfV-OdP5MAx8H`,
      thumbnail: `${google_url}13zo6SgOhhKE995fnJnCWfV-OdP5MAx8H`,
    },
    {
      original: `${google_url}1p-_w64FvjWaan-_8k_Ec7IycBK78z9w0`,
      thumbnail: `${google_url}1p-_w64FvjWaan-_8k_Ec7IycBK78z9w0`,
    },
    {
      original: `${google_url}1-72tss99zFTOehn-f68963FPiMgWRMHx`,
      thumbnail: `${google_url}1-72tss99zFTOehn-f68963FPiMgWRMHx`,
    },
    {
      original: `${google_url}1_5nyU5QLuhuILhe2MpbZlpCJdq-ipE_x`,
      thumbnail: `${google_url}1_5nyU5QLuhuILhe2MpbZlpCJdq-ipE_x`,
    },
    {
      original: `${google_url}1MemS5kP-KCvC9k9mO0FSggXEmH1_PDqu`,
      thumbnail: `${google_url}1MemS5kP-KCvC9k9mO0FSggXEmH1_PDqu`,
    },
    {
      original: `${google_url}1d1MniqfzyvIZjGRrCIO1HJi2vdEgkkKk`,
      thumbnail: `${google_url}1d1MniqfzyvIZjGRrCIO1HJi2vdEgkkKk`,
    },
    {
      original: `${google_url}1OZyRF2x7GyXiMaTYQRG5FneV_cistfik`,
      thumbnail: `${google_url}1OZyRF2x7GyXiMaTYQRG5FneV_cistfik`,
    },
    {
      original: `${google_url}1Hhj8FV7JeV4SP1fgUZF1dLnceQO_fmWF`,
      thumbnail: `${google_url}1Hhj8FV7JeV4SP1fgUZF1dLnceQO_fmWF`,
    },
    {
      original: `${google_url}1uh8HWh0K43TsCuvfs9ph-eaj0Hk42I6a`,
      thumbnail: `${google_url}1uh8HWh0K43TsCuvfs9ph-eaj0Hk42I6a`,
    },
    {
      original: `${google_url}11maLQhCDJrupkZ67epMYDG5SqmqHCpUO`,
      thumbnail: `${google_url}11maLQhCDJrupkZ67epMYDG5SqmqHCpUO`,
    },
    {
      original: `${google_url}1OGGOgmqUbzaU5zNGBnLPUd0bWnyu_EVG`,
      thumbnail: `${google_url}1OGGOgmqUbzaU5zNGBnLPUd0bWnyu_EVG`,
    },
    {
      original: `${google_url}1fRDLU1Akp2-KdmWC3QLffK_z3gs3cze_`,
      thumbnail: `${google_url}1fRDLU1Akp2-KdmWC3QLffK_z3gs3cze_`,
    },
    {
      original: `${google_url}1L51XECLutapID9BTdfqEhWjo6Wxb3gx3`,
      thumbnail: `${google_url}1L51XECLutapID9BTdfqEhWjo6Wxb3gx3`,
    },
    {
      original: `${google_url}1xVJ_oKgPCh3kuSC1An8sATTjzTjSqzAz`,
      thumbnail: `${google_url}1xVJ_oKgPCh3kuSC1An8sATTjzTjSqzAz`,
    },
    {
      original: `${google_url}1ZSVTDekv0x6f8yre_jbTMHglrpjmpDej`,
      thumbnail: `${google_url}1ZSVTDekv0x6f8yre_jbTMHglrpjmpDej`,
    },
    {
      original: `${google_url}1Pd_07ScNUALcN36maKPRNBqwqmWDCg4B`,
      thumbnail: `${google_url}1Pd_07ScNUALcN36maKPRNBqwqmWDCg4B`,
    },
    {
      original: `${google_url}1gv6N_Aa_8wBGyJK_oPSkzkhy-EMKRZnb`,
      thumbnail: `${google_url}1gv6N_Aa_8wBGyJK_oPSkzkhy-EMKRZnb`,
    },
    {
      original: `${google_url}1vn2O_I9cRBOvyABHwfNbOtfjeRKySmLG`,
      thumbnail: `${google_url}1vn2O_I9cRBOvyABHwfNbOtfjeRKySmLG`,
    },
    {
      original: `${google_url}17ZiArbpukYcAW3h1GBRBigAkMibEY5aW`,
      thumbnail: `${google_url}17ZiArbpukYcAW3h1GBRBigAkMibEY5aW`,
    },
    {
      original: `${google_url}1-AW-vEz3cB_beiODO033YbpOKUltx1zo`,
      thumbnail: `${google_url}1-AW-vEz3cB_beiODO033YbpOKUltx1zo`,
    },
    {
      original: `${google_url}1yzSaE-ZgUpQZnRaEOhGXoUDSMMFbC5rs`,
      thumbnail: `${google_url}1yzSaE-ZgUpQZnRaEOhGXoUDSMMFbC5rs`,
    },
  ];
  
  if(!photography.display){
    return null;
  }

  return (
    <div className="main">
      <div id="photography">
        <h1 className="photography-heading">Photography</h1>
        <p> You might be blocked by Google auto-query detection</p> 
        <ImageGallery items={images} />
      </div>
    </div>
  );
}
